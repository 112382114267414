<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Utenti</h4>
                </div>
                <DataTable :lazy='true' ref='dt'
                           :totalRecords='totalRecords'
                           :value='rows'
                           @page='onPage($event)'
                           @sort='onSort($event)'
                           :paginator='true'
                           class='p-datatable-gridlines'
                           :rows='search_params.limit'
                           v-model:filters='filters'
                           :globalFilterFields="['last_name', 'id', 'email', 'status']"
                           dataKey='id'
                           :rowHover='true'
                           filterDisplay='row'
                           :loading='loading'
                           responsiveLayout='scroll'
                >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            @refresh='refreshList()'
                            :hide-new='true'
                            :hide-filter='true'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='id' header='ID' :sortable='true' style='min-width:3rem' :showFilterMenu='false'>
                        <template #filter='{filterModel}'>
                            <InputNumber v-model='filterModel.value' :useGrouping="false" mode='decimal' @keydown.enter="changeFilter('id', $event)"
                                         class='p-column-filter' placeholder='Cerca per id' />
                        </template>
                        <template #body='slotProps'>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>

                    <Column field='email' header='Email' :sortable='true' style='min-width:6rem'
                            :showFilterMenu='false'>
                        <template #filter='{filterModel}'>
                            <InputText type='text' v-model='filterModel.value'
                                       @keydown.enter="changeFilter('email', $event)" class='p-column-filter'
                                       placeholder='Cerca per email' />
                        </template>
                        <template #body='slotProps'>
                            {{ cut(slotProps.data.email, 40) }}
                        </template>
                    </Column>

                    <Column field='last_name' header='Nome completo' :sortable='true' style='min-width:6rem'
                            :showFilterMenu='false'>
                        <template #filter='{filterModel}'>
                            <InputText type='text' v-model='filterModel.value'
                                       @keydown.enter="changeFilter('last_name', $event)" class='p-column-filter'
                                       placeholder='Cerca per nome' />
                        </template>
                        <template #body='slotProps'>
                            {{ cut(slotProps.data.last_name, 40) }}
                        </template>
                    </Column>

                    <Column field='status' header='Note' style='min-width:2rem' :showFilterMenu='false'>
                        <template #filter='{filterModel}'>
                            <InputText type='text' v-model='filterModel.value'
                                       @keydown.enter="changeFilter('status', $event)" class='p-column-filter'
                                       placeholder='Cerca per ID Subscription' />
                        </template>
                        <template #body='slotProps'>
                            <InlineMessage v-tooltip="'L\'utente si è cancellato dalla pagina Profilo'"
                                           v-if='!slotProps.data.email.includes("@")'
                                           severity='error'>
                                Cancellato
                            </InlineMessage>
                            <template v-if='slotProps.data.subscription'>
                                <Tag v-if='slotProps.data.subscription.expired' severity="success" value='FREE'/>
                                <Tag v-else severity="info" :value='slotProps.data.subscription["provider"]'/>

                            </template>
                        </template>
                    </Column>


                    <Column field='id' style='min-width:6rem'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='pointer ml-2'
                            title='Apri in nuovo Tab'
                            :style='{color: colors.bg.HELP}'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-color pointer ml-2'
                            title='Apri nello stesso Tab'
                            :style='{color: colors.bg.INFO}'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        />
                        </template>
                        <template #body='slotProps'>

                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>

                            <font-awesome-icon @click='sync(slotProps.data.id)'
                                               class='text-blue-500 hover:text-blue-600 pointer'
                                               title='Sincronizza con Intercom'
                                               :style='{marginBottom: "3px"}'
                                               :icon="['fab', 'intercom']"
                                               size='3x'
                                               :spin='intercomSyncing===slotProps.data.id'/>
                            <font-awesome-icon @click='doPersonify(slotProps.data.id)'
                                               class='text-teal-500 hover:text-teal-700 pointer ml-1'
                                               title='Impersona utente sul sito'
                                               :style='{marginBottom: "5px", fontSize: "37px"}'
                                               :icon="['fas', 'user-secret']"
                                               size='2x'
                                               :spin='personifying===slotProps.data.id'/>
                            <font-awesome-icon @click='removeCred(slotProps.data.id)'
                                               class='text-pink-600 hover:text-pink-400 pointer ml-1'
                                               title='Cancella credenziali firebase'
                                               :style='{marginBottom: "5px", fontSize: "37px"}'
                                               :icon="['fas', 'user-alt-slash']"
                                               size='2x'
                                               :spin='loading && (rcId===slotProps.data.id)'/>
                        </template>
                    </Column>


                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Users/Users');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import Personify from '../../../mixins/personify';

import ListHeader from '../../../components/4books/ListHeader';

export default {
    components: {
        ListHeader,
    },
    data() {
        return {
            rcId: 0,
            filters: {
                'last_name': { value: '', matchMode: 'equals' },
                'status': { value: '', matchMode: 'equals' },
                'email': { value: '', matchMode: 'equals' },
                'id': { value: null, matchMode: 'equals' },
            },
            search_params: {
                limit: 10,
                order: 1,
                sortBy: 'email',
                page: 1,
                email: '',
            },
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading', 'page', 'pages', 'intercomSyncing']),
        totalRecords() {
            return this.pages * this.search_params.limit;
        },
    },
    created() {
        this.initFilters();
    },
    mounted() {
        //this.refreshList();
    },
    methods: {
        ...mapActions(['search', 'syncIntercom','removeCredentials']),
        removeCred(id){
            this.$confirm.require({
                message: 'Sei sicura di voler piallare le credenziali di questo utente?',
                header: 'Richiesta di conferma',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when user confirms the action
                    this.rcId = id;
                    this.removeCredentials(id).then(res => {
                        if (res['done']){
                            this.ShowSuccess("Ottimo", "Credenziali rimosse");
                        } else {
                            this.ShowError("Attenzione", "Operazione fallita");
                        }
                    }).catch(err => {
                        this.ShowStandardError(err.response);
                    }).finally(() => this.rcId = 0);

                },
                reject: () => {
                    //callback to execute when user rejects the action
                    this.rcId = 0
                }
            });
        },
        cut(str, chars) {
            if (str.length <= chars) return str;
            return str.substring(0, chars) + '...';
        },
        sync(id) {
            this.syncIntercom(id).then(() => {
                this.ShowSuccess('FATTO', 'La sincronizzazione è in atto');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        changeFilter(field, ev) {
            let value = ev.target.value;
            if (field === 'id') {
                value = (value === '') ? null : parseInt(value, 10);
            }
            this.filters[field].value = value;
            this.refreshList();
        },
        onPage(event) {
            this.search_params.page = event.page + 1;
            this.refreshList();
        },
        onSort(event) {
            this.search_params.sortBy = event.sortField;
            this.search_params.order = event.sortOrder;
            this.refreshList();
        },
        refreshList() {
            const payload = {
                ...this.search_params,
                last_name: this.filters.last_name.value,
                email: this.filters.email.value,
                id: this.filters.id.value,
                ext_id: this.filters.status.value
            };
            this.search(payload).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.filters.email.value = '';
            this.filters.last_name.value = '';
            this.filters.id.value = null;
        },
        clearFilter() {
            this.initFilters();
        },
    },
    mixins: [
        Notifications,
        Navigations,
        Personify
    ],
};
</script>
